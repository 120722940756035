<template>
  <div class="login_container">
    <div
      style="
        position: fixed;
        top: 0px;
        bottom: 22%;
        overflow-y: auto;
        width: 100%;
      "
    >
      <!-- 就诊信息-->
      <div class="VisitingInfo" v-for="item in list" :key="item.id">
        <van-cell size="lager" title="就诊人">{{ userName }}</van-cell>
        <van-cell size="lager" title="就诊科室">{{ item.deptId }}</van-cell>
        <van-cell size="lager" title="就诊医生">{{ item.drName }}</van-cell>
        <!-- <van-cell size="lager" title="就诊时间">{{item.VisitingTime}} </van-cell> -->
        <van-cell size="lager" title="订单状态">已支付</van-cell>
        <!--{{item.Orderstatus}}-->
        <van-cell size="lager" title="订单金额">{{ item.totalFee }}</van-cell>
        <van-cell size="lager" title="缴费时间">{{ item.operTime }}</van-cell>

        <!-- <van-cell size="lager" title="就诊序号">{{item.Visitingnumber}}</van-cell> -->

        <!-- 就诊人信息-->
        <div class="VisitingidInfo">
          <span class="VisitingInfo_header">项目名称</span>
          <div v-for="items in item.clinicDetails" :key="items.itemId">
            <van-cell size="lager" :title="items.itemName">{{
              item.cost
            }}</van-cell>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>@拓实科技</span>
    </div>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import store from "../../store";
export default {
  data() {
    return {
      list: [],
      userName: "",
    };
  },
  created() {
    this.userName = store.getters["store/userName"];
    this.onload();
  },
  methods: {
    async onload() {
      let regNo = sessionStorage.getItem("regNo");
      let idcard = store.getters["store/idcard"];
      let seqNo = store.getters["store/seqNo"];
      // let patientid = store.getters["store/patientid"]; //声明病例号
      await ajax
        .post("/Api/FeeService/GetFeeDetail", {
          regNo: regNo,
          seqNo: seqNo,
          idCard: idcard,
        })
        .then((rep) => {
          let listss = rep.data.result.data[0];
          this.list = listss;
          // console.log(listss);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
}

.header {
  float: left;
  width: 100%;
  height: 80px;
  line-height: 80px;
}

.header_code {
  float: left;
  margin: 10px;
}

.hearder_type {
  float: right;
  margin-right: 5%;
  color: #57c4b7;
}

.VisitingInfo {
  width: 100%;
  margin-top: 10px;
  float: left;
}

.card {
  height: 40px;
  width: 90%;
  background: #57c4b7;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  margin-top: 5%;
  left: 5%;
}

.VisitingInfo_header {
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  margin: 15px;
  background-color: #ffffff;
}
</style>
